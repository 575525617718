import React, { useRef } from 'react';
import { Button, FormGroup, Label, Input, Col } from 'reactstrap';
import { TypingIndicator } from "@chatscope/chat-ui-kit-react";

const EvaluateMyAnswer = ({ setQuestion, setSubmittedAnswer, handleSend, evaluatedAnswer, saveToArchive, isTyping }) => {
  const answerRef = useRef(null);

  const handleCopy = () => {
    if (answerRef.current) {
      answerRef.current.select();
      document.execCommand('copy');
    }
  };

  return (
    <>
      <FormGroup>
        <Label for="question">Your Question</Label>
        <Input
          className="input-lb"
          type="textarea"
          name="text"
          id="question"
          onChange={(e) => setQuestion(e.currentTarget.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="answer">Your Answer</Label>
        <Input
          className="input-lb"
          type="textarea"
          name="text"
          id="answer"
          onChange={(e) => setSubmittedAnswer(e.currentTarget.value)}
        />
      </FormGroup>
      <Button type="button" onClick={handleSend}>
        Evaluate My Answer
      </Button>
      {isTyping && (
        <Col xs="12" md="6" lg="8">
          <TypingIndicator content="LB is typing" />
        </Col>
      )}
      {evaluatedAnswer && !isTyping && (
        <FormGroup>
          <Label for="evaluatedAnswer">Evaluated Answer</Label>
          <Input
            className="input-lb"
            type="textarea"
            name="text"
            id="evaluatedAnswer"
            innerRef={answerRef}
            value={evaluatedAnswer}
            readOnly
          />
          <Button onClick={handleCopy}>Copy to Clipboard</Button>
          <Button onClick={saveToArchive}>Archive Results</Button>
        </FormGroup>
      )}
    </>
  );
};

export default EvaluateMyAnswer;