
import Dashboard from "components/Dashboard";
import IELTS from "components/IELTS";
import Archive from "components/Archive";
import CELPIP from "components/CELPIP";
import Vocabulary from "components/Vocabulary";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: "tim-icons icon-chart-pie-36",
    component: <Dashboard />,
    layout: "/user",
  },
  {
    path: "/ielts",
    name: "IELTS",
    rtlName: "الرموز",
    icon: "tim-icons icon-world",
    component: <IELTS exam={"IELTS"}/>,
    layout: "/user",
  },
  {
    path: "/celpip",
    name: "CELPIP",
    rtlName: "الرموز",
    icon: "tim-icons icon-planet",
    component: <CELPIP exam={"CELPIP"}/>,
    layout: "/user",
  },
  {
    path: "/archive",
    name: "ARCHIVE",
    rtlName: "الرموز",
    icon: "tim-icons icon-wallet-43",
    component: <Archive />,
    layout: "/user",
  },
  {
    path: "/vocabulary",
    name: "VOCABULARY",
    rtlName: "المفردات",
    icon: "tim-icons icon-book-bookmark",
    component: <Vocabulary />,
    layout: "/user",
  }
];
export default routes;
