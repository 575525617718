import React, { useState, useEffect, useContext } from 'react';
import { Table, Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import './MyTable.css';
import { db } from '../firebase';
import { doc, getDoc, query, where, collection } from "firebase/firestore";
import { UserContext } from './AuthInterceptor';

const headerMap = {
  evaluatedAnswer: "Evaluated Answer",
  exam: "EXAM",
  question: "QUESTION",
  answer: "ANSWER",
  band: "BAND",
  answer_type: "ANSWER TYPE",
};

const ArchiveTable = ({ actionType, exam }) => {
  const [search, setSearch] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const userDetails = useContext(UserContext);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const docRef = doc(db, "Users", userDetails?.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserData({ ...docSnap.data(), uid: userDetails?.uid });
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [userDetails]);

  useEffect(() => {
    const collectionName = actionType === 'Evaluate my Answer' ? 'Evaluate_My_Answer' : 'Answer_My_Question';
    const ArchiveData = userData ? userData[collectionName] : [];
    const SelectedExamData = ArchiveData?.filter(row => row.exam === exam);
    setData(SelectedExamData || []);

    const cols = collectionName === 'Evaluate_My_Answer' ? ['question', 'answer', 'evaluatedAnswer'] : ['question', 'band', 'answer_type', 'evaluatedAnswer'];
    setColumns(cols);
  }, [actionType, exam, userData]);

  const filteredData = data
    .filter(row => columns.some(column => String(row[column]).toLowerCase().includes(search.toLowerCase())))
    .sort((a, b) => {
      if (!sortColumn) return 0;
      const result = a[sortColumn] > b[sortColumn] ? 1 : -1;
      return sortDirection === 'desc' ? -result : result;
    });

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleViewClick = (row) => {
    setSelectedRow(row);
    setModal(true);
  };

  const toggleModal = () => setModal(!modal);

  return (
    <div>
      <Input 
        type="text" 
        value={search} 
        onChange={e => setSearch(e.target.value)} 
        placeholder="Search..." 
      />
      <Table>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index} onClick={() => handleSort(column)}>
                {headerMap[column]}
              </th>
            ))}
            <th>View</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, index) => (
            <tr key={index}>
              {columns.map((column, index) => (
                <td key={index}>
                  <div className="ellipsis">{row[column]}</div>
                </td>
              ))}
              <td>
                <i className="tim-icons icon-zoom-split" onClick={() => handleViewClick(row)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal isOpen={modal} toggle={toggleModal} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <ModalHeader toggle={toggleModal}>REVIEW</ModalHeader>
        <ModalBody>
          {selectedRow && Object.entries(selectedRow).map(([key, value], index) => (
            <p key={index}><strong>{headerMap[key] || key}:</strong> {value}</p>
          ))}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ArchiveTable;
