import React, { useState } from "react";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { setDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

function SignUp() {
  const navigateTo = useNavigate();
  const [form, setForm] = useState({
    password: '',
    email: '',
    firstName: '',
    lastName: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { email, password, firstName, lastName } = form;
      await createUserWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      if (user) {
        await setDoc(doc(db, "Users", user.uid), {
          email: user.email,
          firstName,
          lastName,
          photo: ""
        });
      }
      toast.success("User Registered Successfully!!", { position: "top-center" });
      navigateTo('/login');
    } catch (error) {
      console.error(error.message);
      toast.error(error.message, { position: "bottom-center" });
    }
  };

  const backToLogin = () => {
    navigateTo("/login");
  };

  return (
    <div className="content">
      <Row>
        <Col md="4"></Col>
        <Col md="4">
          <Card>
            <CardHeader style={{ textAlign: "center" }}>
              <h4 className="title">Welcome to Ai-LangBuddy</h4>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <Row className="pl-5">
                  <Col className="pl-md-1" md="10">
                    <FormGroup>
                      <label>First Name</label>
                      <Input
                        placeholder="First Name"
                        type="text"
                        name="firstName"
                        value={form.firstName}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="pl-5">
                  <Col className="pl-md-1" md="10">
                    <FormGroup>
                      <label>Last Name</label>
                      <Input
                        placeholder="Last Name"
                        type="text"
                        name="lastName"
                        value={form.lastName}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="pl-5">
                  <Col className="pl-md-1" md="10">
                    <FormGroup>
                      <label htmlFor="exampleInputEmail1">Email address</label>
                      <Input
                        placeholder="mail@email.com"
                        type="email"
                        name="email"
                        value={form.email}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="pl-5">
                  <Col className="pl-md-1" md="10">
                    <FormGroup>
                      <label>Password</label>
                      <Input
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={form.password}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <CardFooter>
                  <Button className="btn-fill" color="primary" type="submit">
                    Sign Up
                  </Button>
                  <Button className="btn-fill" color="primary" type="button" onClick={backToLogin}>
                    Back to Login
                  </Button>
                </CardFooter>
              </Form>
            </CardBody>
          </Card>
        </Col>
        <Col md="4"></Col>
      </Row>
    </div>
  );
}

export default SignUp;
