import React from "react";
import { Row, Col } from "reactstrap";
import LBTool from "./LBTool";

const IELTS = ({ exam }) => (
  <div className="content">
    <Row>
      <Col lg="12">
        <LBTool exam={exam} />
      </Col>
    </Row>
  </div>
);

export default IELTS;
