import React, { useRef } from 'react';
import { Button, FormGroup, Label, Input, ButtonGroup, Col } from 'reactstrap';
import { TypingIndicator } from "@chatscope/chat-ui-kit-react";

const AnswerMyQuestion = ({
  answerType,
  onAnswerTypeChange,
  setBand,
  bandList,
  setQuestion,
  handleSend,
  evaluatedAnswer,
  saveToArchive,
  isTyping,
  bandListCelpip,
  examName
}) => {
  const answerRef = useRef(null);

  const handleCopy = () => {
    if (answerRef.current) {
      answerRef.current.select();
      document.execCommand('copy');
    }
  };

  const renderBandOptions = () => {
    const list = examName === 'IELTS' ? bandList : bandListCelpip;
    return list.map(band => (
      <option key={band} value={band}>{band}</option>
    ));
  };

  return (
    <>
      <h3 style={{ marginBottom: "10px", marginTop: "23px" }}>Answer for?</h3>
      <ButtonGroup>
        <Button
          color="primary"
          id="letter"
          name="Letter"
          onClick={() => onAnswerTypeChange('Letter')}
          active={answerType === 'Letter'}
        >
          Letter
        </Button>
        <Button
          color="primary"
          id="essay"
          name="Essay"
          onClick={() => onAnswerTypeChange('Essay')}
          active={answerType === 'Essay'}
        >
          Essay
        </Button>
      </ButtonGroup>
      <FormGroup>
        <Label for="band">What band answer you want:</Label>
        <Input
          type="select"
          name="select"
          id="band"
          onChange={(e) => setBand(e.target.value)}
        >
          {renderBandOptions()}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="question">Question</Label>
        <Input
          className="input-lb"
          type="textarea"
          name="text"
          id="question"
          onChange={(e) => setQuestion(e.currentTarget.value)}
        />
      </FormGroup>
      <Button type="button" onClick={handleSend}>
        Give Me Answer
      </Button>
      {isTyping ? (
        <Col xs="12" md="6" lg="8">
          <TypingIndicator content="LB is typing" />
        </Col>
      ) : (
        <FormGroup>
          <Label for="answer">Answer</Label>
          <Input
            className="input-lb"
            type="textarea"
            name="text"
            id="answer"
            innerRef={answerRef}
            value={evaluatedAnswer}
            style={{
              maxHeight: "700px",
              overflowY: "auto",
              height: "400px",
              minHeight: "200px"
            }}
          />
          <Button onClick={handleCopy}>Copy to Clipboard</Button>
          <Button onClick={saveToArchive}>Archive Results</Button>
        </FormGroup>
      )}
    </>
  );
};

export default AnswerMyQuestion;