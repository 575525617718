import React from 'react';

const SplashScreen = () => {
  return (
    <div className="splash-screen" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <h1>AI-Language Buddy</h1>
      </div>
  );
};

export default SplashScreen;