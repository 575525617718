import { Navigate, Outlet } from 'react-router-dom';
import React, { useEffect, useState, createContext } from "react";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import SplashScreen from './SplashScreen';

export const UserContext = createContext();

export const AuthInterceptor = () => {
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigateTo = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            const unsubscribe = auth.onAuthStateChanged(async (user) => {
                if (!user) {
                    navigateTo("/login");
                    setLoading(false);
                    return;
                }
                const docRef = doc(db, "Users", user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setUserDetails({ ...docSnap.data(), uid: user.uid });
                } else {
                    navigateTo("/login");
                }
                setLoading(false);
            });
            return unsubscribe;
        };

        fetchUserData();
    }, [navigateTo]);

    if (loading) {
        return <SplashScreen />;
    }

    return (
        userDetails ? (
            <UserContext.Provider value={userDetails}>
                <Outlet />
            </UserContext.Provider>
        ) : (
            <Navigate to="/login" />
        )
    );
};

export default AuthInterceptor;
