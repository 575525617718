import React from "react";
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

const examTypes = [
  {
    name: "IELTS",
    iconClass: "tim-icons icon-bell-55 text-info",
    description: "Start practicing and evaluating your IELTS writing essays and letters",
    route: "/user/ielts"
  },
  {
    name: "CELPIP",
    iconClass: "tim-icons icon-delivery-fast text-primary",
    description: "Start practicing and evaluating your CELPIP writing essays and letters",
    route: "/user/celpip"
  }
];

function Dashboard() {
  const navigateTo = useNavigate();

  return (
    <div className="content">
      <Row>
        {examTypes.map((exam, index) => (
          <Col lg="6" key={index}>
            <Card className="card-chart exam-type-card" onClick={() => navigateTo(exam.route)}>
              <CardHeader>
                <h5 className="card-category">Practice</h5>
                <CardTitle tag="h3">
                  <i className={exam.iconClass} /> {exam.name}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area">
                  <p>{exam.description}</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Dashboard;
