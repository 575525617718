
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import RTLLayout from "layouts/RTL/RTL.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'react-toastify/dist/ReactToastify.css';
import "index.css";
import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import AuthInterceptor from "./components/AuthInterceptor";
import ForgotPassword from "./components/ForgotPassword";
import LogIn from "./components/Login";
import SignUp from "./components/Signup";
import { ToastContainer } from 'react-toastify';
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
        <Route path="/login" index element={<LogIn/>} />
        <Route path="/signup" index element={<SignUp/>} />
        <Route path="/forgotPassword" index element={<ForgotPassword/>} />
        <Route path="/" element={<AuthInterceptor/>} >
          <Route path="/" index element={<AdminLayout />} />
          <Route path="/user/*" element={<AdminLayout />} />
        </Route>
        {/* <Route
          path="*"
          element={<Navigate to="/test/dashboard" replace />}
        /> */}
        </Routes>
      </BrowserRouter>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>
);
