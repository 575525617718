import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const ProgressBarChart = ({ totalWords, learnedWords }) => {
  const percentage = (learnedWords / totalWords) * 100;

  return (
    <div>
      {/* <h3>Total Words: {totalWords}</h3> */}
      <ProgressBar now={percentage} label={`${learnedWords} words`} />
    </div>
  );
};

export default ProgressBarChart;