import { useState, useContext } from "react";
import "./LBTool.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import { db } from '../firebase';
import { addDoc, doc, collection, updateDoc, arrayUnion } from "firebase/firestore";
import { toast } from "react-toastify";
import { Container, Row, Col, Button, Input, FormGroup, Label, Form, CustomInput } from 'reactstrap';
import EvaluateMyAnswer from './EvaluateMyAnswer';
import AnswerMyQuestion from './AnswerMyQuestion';
import { UserContext } from './AuthInterceptor';

const API_KEY = "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";

const PROMPTS = {
  EVALUATE_MY_ANSWER: `You are an experienced IELTS examiner and teacher with 20 years of expertise. 
    Assess the answer based on the four key IELTS writing evaluation criteria: 
    Task Achievement, Coherence and Cohesion, Lexical Resource, and Grammatical Range and Accuracy. 
    Present your evaluation and suggestions in well structured readable format with section headings in capitals and bold with following sections,
    Overall Band: The targeted band score.
    Total Words: Exact word count.
    Vocab Complexity: Example value (e.g., C1 - Intricate).
    Score on Coherence & Cohesion: Rating out of 9.
    Score on Lexical Resource: Rating out of 9.
    Score on Grammatical Range & Accuracy: Rating out of 9.
    Total Grammar Errors: Count of grammatical mistakes.
    Afterward in FEEDBACK section with heading FEEDBACK: analyze the response in detail, highlighting specific grammatical errors, sentence structure issues, and areas where it falls short in these criteria. 
    Provide clear, actionable feedback on how to improve the response to achieve a perfect 9 band score.`,
  EVALUATE_MY_ANSWER_CELPIP: `You are an experienced CELPIP examiner and teacher with 20 years of expertise. 
    Carefully evaluate the provided response to the given writing task and assign a level score out of 12. 
    Assess the answer based on the four key CELPIP writing evaluation criteria: Task Response, Coherence and Organization, Lexical Range, and Grammar and Accuracy. 
    Present your evaluation and suggestions in well structured readable format with section headings in capitals and bold with following sections,
    Overall Band: The targeted band score.
    Total Words: Exact word count.
    Vocab Complexity: Example value (e.g., C1 - Intricate).
    Score on Coherence & Cohesion: Rating out of 12.
    Score on Lexical Resource: Rating out of 12.
    Score on Grammatical Range & Accuracy: Rating out of 12.
    Total Grammar Errors: Count of grammatical mistakes.
    Afterward, in FEEDBACK section with heading FEEDBACK: analyze the response in detail, highlighting specific grammatical errors, sentence structure issues, and areas where it falls short in these criteria. 
    Provide clear, actionable feedback on how to improve the response to achieve a perfect level 12 score.`,
  GIVE_ME_ANSWER_LETTER: (band) => `You are an experienced IELTS teacher with 20 years of expertise. Your task is to write a letter for the given IELTS Task 1 prompt. Strictly limit your response to 170-220 words. The letter should aim to qualify for a band ${band} score.
    After writing the letter, explain why your response qualifies for band ${band}. Provide an analysis of how your answer meets the criteria for this band, focusing on key aspects such as tone, structure, and task achievement.
    Also, calculate and display the total word count.
    In your response, detail the vocabulary, expressions, and sentence structures used, highlighting any strategies or language features that contribute to the score.
    Finally, respond in in well structured readable format with section headings in capitals:`,
  GIVE_ME_ANSWER_ESSAY: (band) => `You are an experienced IELTS teacher with 20 years of expertise. Your task is to write an essay for the given IELTS Task 2 prompt. Strictly limit your response to 270-330 words. The essay should aim to qualify for a band ${band} score.
    After writing the essay, explain why your response qualifies for band ${band}. Provide a detailed analysis of how your answer meets the criteria for this band, focusing on aspects such as task response, argument development, and clarity.
    Also, calculate and display the total word count.
    In your explanation, highlight the tricks, phrases, and vocabulary used, explaining how they enhance the essay’s quality and contribute to a higher band score.
    Finally, respond in in well structured readable format with section headings in capitals`,
  GIVE_ME_ANSWER_LETTER_CELPIP: (band) => `You're an experienced CELPIP instructor with 20 years of expertise. Your task is to write a letter for the given CELPIP Task 1 prompt. Strictly limit your response between 180 words to the maximum of 210 words. The letter should aim to qualify for a CLB ${band} equivalent in CELPIP. After writing the letter, provide an explanation of why it meets the criteria for CLB ${band}.
   After writing the letter, explain why your response qualifies for band ${band}. Provide an analysis of how your answer meets the criteria for this band, focusing on key aspects such as tone, structure, and task achievement.
    Also, calculate and display the total word count.
    In your response, detail the vocabulary, expressions, and sentence structures used, highlighting any strategies or language features that contribute to the score.
    Finally, respond in in well structured readable format with section headings in capitals:`,
  GIVE_ME_ANSWER_ESSAY_CELPIP: (band) => `You are an experienced CELPIP instructor with 20 years of expertise. Your task is to respond to the given CELPIP Task 2 survey question. Strictly limit your response to 170-210 words. The response should aim to qualify for a band ${band} score.
    After writing the response, provide a detailed explanation of why it meets the criteria for band ${band}. Include an analysis of the strategies, phrases, and vocabulary used to enhance the quality of the response.
    Calculate and display the total word count. Evaluate the response and respond in in well structured readable format with section headings in capitals`
};

function LBTool({ exam }) {
  const [messages, setMessages] = useState([
    {
      message: "Welcome to AI-Language Buddy",
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ]);

  const [mainOptions, setMainOptions] = useState([
    { name: "Evaluate my Answer", value: 1 },
    { name: "Answer my question", value: 2 },
  ]);

  const [band, setBand] = useState("");
  const bandList = ["5", "6", "6.5", "7", "7.5", "8", "8.5", "9"];
  const bandListForCelpip = ["5", "6", "7", "8", "9", "10", "11", "12"];
  const [selectedAction, setSelectedAction] = useState(1);
  const [question, setQuestion] = useState("");
  const [submittedAnswer, setSubmittedAnswer] = useState("");
  const [answerType, setAnswerType] = useState("Letter");
  const [evaluatedAnswer, setEvaluatedAnswer] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [systemMessage, setSystemMessage] = useState({ role: 'system', content: PROMPTS.EVALUATE_MY_ANSWER });
  const userDetails = useContext(UserContext);

  const handleSend = async () => {
    let newMessage = {};
    let generatedSystemMsg = {};

    if (selectedAction === 1) {
      const wordCount = submittedAnswer.trim().split(/\s+/).length;
      if (wordCount < 100) {
        toast.error('The answer must be at least 100 words.', { position: "top-center" });
        return;
      }

      newMessage = {
        message: question + " \n " + submittedAnswer,
        direction: "outgoing",
        sender: "user",
      };

      generatedSystemMsg = {
        role: "system",
        content: exam === "IELTS" ? PROMPTS.EVALUATE_MY_ANSWER : PROMPTS.EVALUATE_MY_ANSWER_CELPIP
      };
    } else if (selectedAction === 2) {
      newMessage = {
        message: question,
        direction: "outgoing",
        sender: "user",
      };

      if (exam === "IELTS") {
        generatedSystemMsg = {
          role: "system",
          content: answerType === "Letter" ? PROMPTS.GIVE_ME_ANSWER_LETTER(band) : PROMPTS.GIVE_ME_ANSWER_ESSAY(band)
        };
      } else {
        generatedSystemMsg = {
          role: "system",
          content: answerType === "Letter" ? PROMPTS.GIVE_ME_ANSWER_LETTER_CELPIP(band) : PROMPTS.GIVE_ME_ANSWER_ESSAY_CELPIP(band)
        };
      }
    }

    const newMessages = [...messages, newMessage];
    setMessages(newMessages);
    setSystemMessage(generatedSystemMsg);
    setIsTyping(true);
    await processMessageToChatGPT(newMessages, generatedSystemMsg);
  };

  async function processMessageToChatGPT(chatMessages, generatedSystemMsg) {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
      return { role: role, content: messageObject.message };
    });

    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [generatedSystemMsg, ...apiMessages],
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => data.json())
      .then((data) => {
        setMessages([
          ...chatMessages,
          {
            message: data.choices[0]?.message?.content ?? "Couldn't run",
            sender: "ChatGPT",
          },
        ]);
        setEvaluatedAnswer(data.choices[0]?.message?.content ?? "Error Occurred, Please try again");
        setIsTyping(false);
      });
  }

  const whatTodo = (option) => {
    setSelectedAction(parseInt(option));
    setSubmittedAnswer("");
    setEvaluatedAnswer("");
  };

  const onAnswerTypeChange = (name) => {
    setAnswerType(name);
    setEvaluatedAnswer("");
  };

  const saveToArchive = async () => {
    const TABLE = selectedAction === 1 ? "Evaluate_My_Answer" : "Answer_My_Question";
    let data;

    if (selectedAction === 1) {
      data = {
        question: question,
        answer: submittedAnswer,
        evaluatedAnswer: evaluatedAnswer,
        exam: exam
      };
    } else {
      data = {
        question: question,
        answer_type: answerType,
        band: band,
        evaluatedAnswer: evaluatedAnswer,
        exam: exam
      };
    }

    try {
      const userDocRef = doc(db, "Users", userDetails.uid);
      await updateDoc(userDocRef, {
        [TABLE]: arrayUnion(data)
      });
      toast.success("Archived Successfully!!", { position: "top-center" });
    } catch (error) {
      toast.error(error.message, { position: "bottom-center" });
    }
  };

  return (
    <div className="App">
      <Container>
        <Row>
          <Col xs="12" md="6" lg="12">
            <Form>
              <FormGroup>
                <Label for="selectAction">Select Action</Label>
                <Input type="select" name="select" id="selectAction" onChange={(e) => whatTodo(e.target.value)}>
                  {mainOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              {selectedAction === 1 ? (
                <EvaluateMyAnswer setQuestion={setQuestion} setSubmittedAnswer={setSubmittedAnswer} handleSend={handleSend} evaluatedAnswer={evaluatedAnswer} saveToArchive={saveToArchive} isTyping={isTyping} examName={exam} />
              ) : (
                <AnswerMyQuestion answerType={answerType} onAnswerTypeChange={onAnswerTypeChange} setBand={setBand} bandList={bandList} bandListCelpip={bandListForCelpip} setQuestion={setQuestion} handleSend={handleSend} evaluatedAnswer={evaluatedAnswer} saveToArchive={saveToArchive} isTyping={isTyping} examName={exam} />
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LBTool;
