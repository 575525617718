import React, { useState, useEffect, useContext } from 'react';
import { Card, CardBody, CardTitle, CardText, Button, Row, Col, CardHeader } from 'reactstrap';
import axios from 'axios';
//import  vocabularyWords  from './vocabularyWords.js'; 
import DoughnutChart from './Charts/DoughnutChart';
import ProgressBarChart from './Charts/ProgressBarChart';
import { collection, query, getDocs, doc,  updateDoc, arrayUnion  } from "firebase/firestore";
import { db } from "../firebase";
import { UserContext } from './AuthInterceptor';
import { toast } from "react-toastify";
const API_KEY = "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";

const Vocabulary = () => {

  const [vocabInfo, setVocabInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [flipped, setFlipped] = useState(false);
  const [vocabularyWords, setVocabularyWords] = useState([]);
  const [vocabularyWordsCount, setVocabularyWordsCount] = useState(0);
  
  const userDetails = useContext(UserContext);
  const [learnedWordsCount, setLearnedWordsCount] = useState( userDetails?.learned_words?.length || 0);
  const fetchWordInfo = async (word) => {
    const prompt = `Teach me the english word - ${word}, few easy sample sentences, its best use cases, please write in English only, return a json response with the word, meaning, sample sentences, usages, and appropriate places which can be parsed into usable JSON.`;

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content: prompt }],
          max_tokens: 150,
        },
        {
          headers: {
            'Authorization': `Bearer ${API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const content = response.data.choices[0].message.content;
      return JSON.parse(content);

    } catch (error) {
      console.error(`Error fetching information for word ${word}:`, error);
      setError(`Error fetching information for word ${word}`);
      return null;
    }
  };

  useEffect(() => {
    const fetchVocabularyWords = async () => {
      try {
        const q = query(collection(db, "Vocabulary"));
        const querySnapshot = await getDocs(q);
        const words = [];
        querySnapshot.forEach((doc) => {
          words.push(...doc.data().words);
        });
        setVocabularyWordsCount(words.length);
        if (userDetails?.learned_words) {
          const filteredWords = words.filter(word => !userDetails.learned_words.includes(word));
          setVocabularyWords(filteredWords || words);
        } else {
          setVocabularyWords(words);
        }
      } catch (error) {
        console.error("Error fetching vocabulary words: ", error);
      }
    };

    fetchVocabularyWords();
  }, []);

  const handleMarkAsLearned = async () => {
    try {
      console.log("userDetails.uid", userDetails  )
      const userDocRef = doc(db, "Users", userDetails.uid);
      await updateDoc(userDocRef, {
        learned_words: arrayUnion(vocabInfo.word)
      });
      console.log(`${vocabInfo.word} marked as learned`);
     
       // Remove the word from vocabularyWords
       setVocabularyWords(prevWords => prevWords.filter(word => word !== vocabInfo.word));
       setLearnedWordsCount(prev => prev + 1);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  useEffect(() => {
  
    const fetchRandomWordInfo = async () => {
      setLoading(true);
      setError(null);
      const randomWord = vocabularyWords[Math.floor(Math.random() * vocabularyWords.length)];
      try {
        const wordInfo = await fetchWordInfo(randomWord);
        setVocabInfo(wordInfo);
      } catch (error) {
        console.error(`Error fetching information for word ${randomWord}:`, error);
        setError(`Error fetching information for word ${randomWord}`);
      } finally {
        setLoading(false);
      }
    };
    if((vocabularyWords.length !== 0)) {
      fetchRandomWordInfo();
    }
    
  }, [vocabularyWords]);

  const getCard = () => {
    if (loading) {
        return <div>Loading...</div>;
      }
    
      if (error) {
        return <div>{error}</div>;
      }
    
      if (!vocabInfo) {
        return <div>No vocabulary information available.</div>;
      }
    
      return  vocabInfo && (
        <div className="card-container">
          <div className={`card-flip ${flipped ? 'flipped' : ''}`}>
            <Card className="card-front">
              <CardBody className="card-body">
                <CardTitle tag="h3">{vocabInfo?.word}</CardTitle>
                <CardText>{vocabInfo?.meaning}</CardText>
                <CardText>Click "View Definition" to see more details.</CardText>
                <Button className="card-button" onClick={handleFlip}>View Definition</Button>
                <Button color="success" className="card-button" onClick={handleMarkAsLearned}>Mark as Learned</Button>
              </CardBody>
            </Card>
            <Card className="card-back">
              <CardBody className="card-body">
                <CardTitle tag="h3">{vocabInfo?.word}</CardTitle>
                <CardText><strong>Meaning:</strong> {vocabInfo.meaning}</CardText>
                <CardText><strong>Sample Sentences:</strong>
                  <ul>
                    {vocabInfo.sample_sentences.map((sentence, idx) => (
                      <li key={idx}>{sentence}</li>
                    ))}
                  </ul>
                </CardText>
                <CardText><strong>Usages:</strong> {vocabInfo?.usages}</CardText>
                <CardText><strong>Appropriate Places:</strong> {vocabInfo?.appropriate_places}</CardText>
                <Button className="card-button" onClick={handleFlip}>Hide Definition</Button>
                <Button color="success" className="card-button" onClick={handleMarkAsLearned}>Mark as Learned</Button>
              </CardBody>
            </Card>
          </div>
        </div>
      )
    };
  
  
  
const handleFlip = () => {
    setFlipped(!flipped);
  };

  const totalWords = 1000; // Example total words
  const learnedWords = 450; // Example learned words
  return (
    <div className='content'>
      <h1>Vocabulary</h1>
      <h3>Learn a new word every day!</h3>
      <Row>
          <Col lg="4">
            <Card className="card-chart">
              <CardHeader>
                <h5 className="card-category">Total Words</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-bell-55 text-info" /> {vocabularyWordsCount}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <div className="chart-area" style={{ width: '100%', height: '100%' }}>
                    {/* <DoughnutChart data = {[(vocabularyWordsCount - learnedWordsCount), learnedWordsCount]} labels={["New", "Learned"]}/> */}
                    <ProgressBarChart totalWords={vocabularyWordsCount} learnedWords={learnedWordsCount} />
                </div>
              </CardBody>
            </Card>
          </Col>
          </Row>
      <Row>
        <Col sm="12">
            
        </Col>
      </Row>
      <Row>
        <Col sm="12">
                {getCard()}
        </Col>
        
      </Row>
      
    </div>
  );
};

export default Vocabulary;