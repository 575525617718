import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import classnames from 'classnames';
import ArchiveTable from './ArchiveTable';

const Archive = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [actionType, setActionType] = useState('Evaluate my Answer');

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const columns = ['Name', 'Score', 'Date'];

  const data = {
    '1': [
      { Name: 'John Doe', Score: 8.5, Date: '2020-01-01' },
      // More data...
    ],
    '2': [
      { Name: 'Jane Doe', Score: 10, Date: '2020-02-01' },
      // More data...
    ]
  };

  const examType = activeTab === '1' ? 'IELTS' : 'CELPIP';

  return (
    <div className="archive content">
      <Nav tabs>
        {['1', '2'].map(tab => (
          <NavItem key={tab}>
            <NavLink
              className={classnames({ active: activeTab === tab })}
              onClick={() => toggleTab(tab)}
            >
              {tab === '1' ? 'IELTS' : 'CELPIP'}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <div className="dropdown-container" style={{ justifyContent: 'flex-start' }}>
        <span style={{ marginRight: '5px' }}>Select Type - </span>
        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle caret size="sm">
            {actionType}
          </DropdownToggle>
          <DropdownMenu>
            {['Evaluate my Answer', 'Answer my question'].map(type => (
              <DropdownItem key={type} onClick={() => setActionType(type)}>
                {type}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
      <TabContent activeTab={activeTab}>
        {['1', '2'].map(tab => (
          <TabPane tabId={tab} key={tab}>
            <ArchiveTable columns={columns} data={data[tab]} actionType={actionType} exam={examType} />
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default Archive;
