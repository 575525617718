import React from "react";
import { Doughnut } from "react-chartjs-2";

const DoughnutChart = ({ data, labels }) => {
  const customLabels = labels.map((label, index) => `${label}: ${data[index]}`);

  const chartdata = {
    labels: customLabels,
    datasets: [
      {
        label: "Markets Monitored",
        backgroundColor: [
         
          "#959595",
          "#83ce83",
          "#f96a5d",
          "#00A6B4",
          "#6800B4",
        ],
        data: data,
      },
    ],
  };

  return (
    <Doughnut
      data={chartdata}
      options={{
        legend: { display: true, position: "right" },
        defaultFontSize:"14px",
      
        datalabels: {
          display: true,
          color: "white",
        },
        tooltips: {
          backgroundColor: "#5a6e7f",
        },
      
        options: {
          responsive: true,
          // maintainAspectRatio: false,
          // aspectRation: 1,
        }
        
      }}
    />
  );
};

export default DoughnutChart;