import { signInWithEmailAndPassword , GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import React, { useState, useEffect } from "react";
import { auth, db  } from '../firebase';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import GoogleButton from "react-google-button";
import { setDoc, doc } from "firebase/firestore";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import SplashScreen from "./SplashScreen";

function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigateTo = useNavigate();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }, []);

    if (loading) {
      return (
        <SplashScreen/>
      );
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
        await signInWithEmailAndPassword(auth, email, password);
        console.log("User logged in Successfully");
        //window.location.href = "/profile";
        toast.success("User logged in Successfully", {
            position: "top-center",
        });
        navigateTo("/");
        } catch (error) {
        console.log(error.message);

        toast.error(error.message, {
            position: "bottom-center",
        });
        }
    };

    function googleLogin() {

      

      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider).then(async (result) => {
        console.log(result);
        const user = result.user;
        if (user) {
          await setDoc(doc(db, "Users", user.uid), {
            email: user.email,
            firstName: user.displayName,
            photo: user.photoURL,
            lastName: "",
          });
        }
        toast.success("User logged in Successfully", {
          position: "top-center",
        });
        navigateTo("/");
      }).catch((error) => {
        console.log(error.message);
        toast.error(error.message, {
          position: "bottom-center",
        });
      });
    }

    const handleSignUpClick = () => {
      navigateTo("/signup");
      //history.push('/signup');
    };

    const handleForgotPassUpClick = () => {
      navigateTo("/forgotPassword");
      //history.push('/signup');
    };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="4"></Col>
          <Col md="4 mt-5">
            <Card style={{display: "flex", alignItems: 'center'}}>
              <CardHeader>
                <h3 className="title">Welcome to <span style={{color:"#rgb(255 108 232)"}}>AI-LangBuddy</span></h3>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="px-md-1" md="12">
                      <GoogleButton onClick={googleLogin}/>
                      <Row style={{fontSize: '20px', color: '#ccc6c6'}} className="d-flex justify-content-center pt-3">OR</Row>
                      <hr style={{border: '1px solid #575353'}}/>
                      <FormGroup>
                        {/* <label>Username</label> */}
                        <Input
                          type="text"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-md-1" md="12">
                      <FormGroup>
                        {/* <label>Password</label> */}
                        <Input
                          type="password"
                          placeholder="Enter password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              {/* <CardFooter>
              <Row>
                <Button className="btn-fill" color="primary" type="submit" onClick={handleSubmit}>
                  LogIn
                </Button>
                <GoogleButton/>
                </Row>
                <Row>
                    <Col className="pr-md-1" md="6">
                        <Button className="btn-fill" color="primary" type="submit">
                            Forgot Password
                        </Button>
                    </Col>
                    <Col className="px-md-1" md="6">
                        <Button className="btn-fill" color="primary" type="submit">
                            Sign Up
                         </Button>
                    </Col>
                  </Row>
              </CardFooter> */}
              <CardFooter>
              <Row className="d-flex justify-content-between">
                <Button className="btn-fill" color="primary" type="submit" onClick={handleSubmit}>
                  LogIn
                </Button>
                {/* <Col className="px-md-1 btn-sm" md="6"> */}
                <Button onClick={handleForgotPassUpClick} className="btn-fill" color="primary" type="submit">
                    Forgot?
                </Button>
                {/* </Col> */}
              </Row>
              <Row className="d-flex justify-content-between">
                <Col className="pr-md-1 btn-sm" md="12">
                  <Button onClick={handleSignUpClick} className="btn-fill" color="primary" type="submit">
                    Sign Up Today
                  </Button>
                </Col>
              </Row>
            </CardFooter>
            </Card>
          </Col>
          <Col md="4"></Col>
          {/* <Col md="4">
            <Card className="card-user">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar"
                      src={require("assets/img/emilyz.jpg")}
                    />
                    <h5 className="title">Mike Andrew</h5>
                  </a>
                  <p className="description">Ceo/Co-Founder</p>
                </div>
                <div className="card-description">
                  Do not be scared of the truth because we need to restart the
                  human foundation in truth And I love you like Kanye loves
                  Kanye I love Rick Owens’ bed design but the back is...
                </div>
              </CardBody>
              <CardFooter>
                <div className="button-container">
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="google">
                    <i className="fab fa-google-plus" />
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Col> */}
        </Row>
      </div>
    </>
  );
}

export default Login;
