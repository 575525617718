import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { toast } from 'react-toastify';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export const ForgotPassword = () => {
  const [resetEmail, setResetEmail] = useState("");
  const navigateTo = useNavigate();
  const resetPassword = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, resetEmail);
      toast.success("Check your email for reset link", {
        position: "top-center",
      });
    } catch (err) {
      toast.error(err.message, {
        position: "bottom-center",
      });
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="6">
          <h2 className="text-center">Reset Password</h2>
          <Form onSubmit={resetPassword}>
            <FormGroup>
              <Label for="resetEmail">Email</Label>
              <Input
                type="email"
                name="email"
                id="resetEmail"
                placeholder="Enter your email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </FormGroup>
            <Button color="primary" type="submit" block>
              Reset Password
            </Button>
            <Button onClick={()=> navigateTo('/login')} color="primary" type="submit" block>
              Back to Login
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;