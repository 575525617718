// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, getAuth, updateProfile } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBjVXVN0mkrJiHFx6eWVcyfFGRINReiGEA",
  authDomain: "language-buddy-150d5.firebaseapp.com",
  projectId: "language-buddy-150d5",
  storageBucket: "language-buddy-150d5.appspot.com",
  messagingSenderId: "693864365317",
  appId: "1:693864365317:web:e26f82061a5b05f08b4b6c",
  measurementId: "G-0736E1738J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
//export const GoogleAuthProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
const analytics = getAnalytics(app);

export default app;